//"use client";

import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { ScrollArea } from "./components/ui/scroll-area";
import { Switch } from "./components/ui/switch";
import { Label } from "./components/ui/label";
import {
  ThumbsUp,
  ThumbsDown,
  Share2,
  PlaySquare,
  SkipForward,
  SkipBack,
} from "lucide-react";
import ReactPlayer from "react-player/youtube";
import { useEffect, useRef, useState } from "react";
import {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "./components/ui/context-menu";
import { Progress } from "./components/ui/progress";
import React from "react";
import { useVideo } from "./VideoContext";
import YouTube, { YouTubeProps } from "./lib/YouTube";
//import YouTubePlayer from "./components/elements/ytplayer";
import YouTubeVideo from "./components/elements/youtubevideo";

const placeholdervideos = [
  "-_HgDQ9LETA",
  "T240sOIer0o",
  "EHg1WLqrZOU",
  "Da-8GHelH0s",
  "gdYARv2J_fI",
  "yHFnIgCoYmo",
  "F_WWad2YRqw",
  "lwU2ficlHrM",
  "E-H9D1x5YOk",
  "IA4-hDHQnqw",
  "bL3baWc-u0Q",
  "WKDNxgQ7XaE",
  "NW559NfkZgk",
  "DTMb70eqZrU",
  "lDt2JNbHCqc",
  "RbvSg9-MOQ8",
  "Y57_WEMz9i4",
  "O6J7C_rLb1U",
  "cugalhvs7B8",
  "yGZr98GEs0U",
  "R1MECfpUfag",
  "6nT3_DP2Uuw",
  "spJP8KbVvU4",
  "-jIDYImzhfA",
  "Bkme1PubX-4",
  "EyNd_iugV-s",
  "UxksoExrJB0",
  "998HstA76_M",
  "4PNPcRDAm0w",
  "r8KPGojTi1E",
  "klVaXvGRKD8",
  "bcnDRGSs5EI",
  "Axt2cOaOUtA",
  "TrILVVwWR6M",
  "ttz6JwzLvK0",
  "xO2aYG4jFiQ",
  "jNptyfJtuQw",
  "kH0P8bIng-U",
  "WqBkN6dZggI",
  "cH3jgInoD3U",
  "QjaHVJQcQVs",
  "Ygai9LRmWG4",
  "plRShIfFupc",
  "8Ql0pWw9tA4",
  "jswnNviYMnU",
  "k9l5VKgoOME",
  "OG_e8ve0l_w",
  "4TDJMwTNv0w",
  "ZxBFoWXMX80",
  "5w-PGrXtmW8",
  "L79ZNHr12pM",
  "Z8v-jBVc9pk",
  "SMXIwsoSqLY",
  "xTg6S5CrDqA",
  "zU0f04F6HLY",
  "aTK4DCTqrMI",
  "PIRAdd_Mo20",
  "_W2CzvNJcHY",
  "3YW9zJGxeWk",
  "U010scclC0E",
  "O9P_3WmknZE",
  "qqQuqqcL_-U",
  "uAIMCHSqSAg",
  "R0xdSLfYxq8",
  "f-aL5kH3lD0",
  "F6iZtodJnCY",
  "e6UW1sVyJcs",
  "NkthosMDNoQ",
  "8MMNh8y2mHM",
  "A5dh9aBXYyk",
  "rkX9HWVwqPQ",
  "nzfFxkG4bnY",
  "MEtKd9v9K2U",
  "Qn7lOZf_xGM",
  "bpJLEpsEd84",
  "W4AcveHnDzg",
  "6voXg6DsZV8",
  "jIebXtezLy8",
  "gV_c88yH2O8",
  "Hp9ihTf4aa0",
  "yTC1VrCibfM",
  "jyXBngR-Ysc",
  "q28tKJPHts4",
  "K6cyZaoFhHg",
  "3eYyC5VdftU",
  "M1fioflxEH0",
  "ld1kprbSNZU",
  "w6HZr6tWb0U",
  "m8TmubqRiyw",
  "jerwwYPq2Fo",
  "lpjYooyv500",
  "3IjgI-Ms1pw",
  "peA0L9dwaHE",
  "7zQIhiYjmD4",
  "GvUCyQPx_kI",
  "PgCknkwzDwc",
  "iA0sXUOAK5A",
  "0tGZ02VHQRc",
  "bNMOSdp0mb8",
  "eHZYffwbVrw",
  "gsmVqtBLtAw",
  "6GCMkfxUvOg",
  "3YaUvO3PrRw",
  "LUqFYnFQhcI",
  "aTFeIa5iyxg",
  "Y6dRsllCWKU",
  "0ppfzG1oVj0",
  "JYvOMW1KRJ0",
  "NplJ1ciVpD4",
  "-nFpwubfS0E",
  "4Q7QV5wB0Ps",
  "9qhg88_FIzM",
  "wNdkdWwEWQc",
  "bPyMQRcKAcE",
  "x_a5E6JxjxM",
  "FkVdFNsbu4E",
  "qrDn6MvKN30",
  "A5TXomTxPA8",
  "61HcB3nw9Ag",
  "i9qzVYM9TTA",
  "15WXquLrops",
  "-_PNSAogPFY",
  "NmmGR7hmiOc",
  "k6EdYyV8T_A",
  "iRFC6JsPUrY",
  "awM4aBYXQ68",
  "KHHfL2Rvkms",
  "dWhBL9X-ihw",
  "fcThyeuJBec",
  "nTVdFeOriIc",
  "ubPzzG1Neq0",
  "mLe0xQQcikQ",
  "Owifjim8xL8",
  "b2AIJ6s0C_A",
  "FaAaC4vLMU0",
  "miR5V6vUsw8",
  "m5CkYNcW4vU",
  "9-kS3jEzVeg",
  "h6ZGkFWt8ts",
  "wWjSssXYOSY",
  "xHkklQh1uS8",
  "hoY0jtQ4h-o",
  "h5JC02ne81w",
  "YdG9Yj9v3DI",
  "VW56wVCtsXM",
  "FKnVhSp0vFM",
  "MzqhQ5Gs1B8",
  "P-obAOr40ek",
  "eqEmKqZ4mT8",
  "PpYcOpLFmHg",
  "02cwj_mK3_Y",
  "NIOsIghuhM8",
  "Svmrq9Xg4_0",
  "4hGDddRQnN0",
  "PJ6Eu2PXA00",
  "g24kNfxsBGE",
  "PfmGej48v0Q",
  "iKTgR0Ur2cU",
  "SZ_gBACzCxQ",
  "sKIU2xXRlpE",
  "-kJkCT5vkkw",
  "2HhJhuaFaBM",
  "hbXtuMNLtKI",
  "uK83L8aGes0",
  "UJxfFNLEmvc",
  "jM04Uwiqfh4",
  "jYlLv0z-sjU",
  "KJb52ybjAtY",
  "5_b35CG0zPQ",
  "zxsit6vn7Co",
  "ty4wBfdNJrM",
  "l7MJ0aA78fc",
  "m_QBH_rCLv0",
  "kiOJpAPHF5E",
  "5p47fKjFq4Y",
  "FySnGU37i2s",
  "IfDe-bd1dlE",
  "HXUH3rwZPQk",
  "gu-xw7ZsE3Q",
  "Eov-hjk-y4U",
  "whnZFzz2NP8",
  "kPmEMhtXrDU",
  "fLSy-uZ1O0s",
  "Rf5yGaALwW4",
  "SH6SX7YKY9c",
  "sb_9yOUKx08",
  "DLOovD_1-Tc",
  "DMZaASBSJYQ",
  "qwQbEgJ8v3E",
  "rfHospH6SV0",
  "DqM7S_TUaNw",
  "95Fon4IaiWg",
  "jMT_sgEYSzs",
  "5orDaiv6UYg",
  "zvmZN3RUQ_g",
  "1siribuntDA",
  "e1uR_kzQyVY",
  "aqC6QH9aMSQ",
  "Kty3zD6b7kM",
  "MgWfrPD71ME",
  "tuS2dosNTpo",
  "P474IUlEO2Q",
  "22Lf_uii0Hg",
  "u-ratvu8vmQ",
  "azli9jA6NyM",
  "V21DUmlsXEs",
  "gIj1oPzuWlk",
  "VItw-ja--ng",
  "hsFzFuatC9A",
  "hE4udPHvYkw",
  "u-XjprSgqVA",
  "pkwwoyH4Go8",
  "V9fEFjBaOZE",
  "f29cje8m0Bg",
  "FQOVUkpiLnc",
  "WHkewGc9n58",
  "c73wFvKkatc",
  "1T3Uq03iGT8",
  "MBW3SwAS1oc",
  "zx9mpels2SI",
  "9kXaZUPwCwg",
  "sbwBb64tzSw",
  "pz5c4JCZMmo",
  "v5UOhBVJzPA",
  "w3498O0ajnU",
  "zTYeXbYUebQ",
  "MTMYqzpTPJI",
  "Si8PyULlt7Q",
  "ob8wOG-pYjM",
  "iWFmyV5XGCw",
  "yEat9fB2ZbU",
  "eACOW7kx8D4",
  "QY1aKAaFKc0",
  "V2ab2Yau19o",
  "CqZk_uYOd7Q",
  "Q2uCgulo_Ws",
  "5_8IwQIB7hk",
  "UoDc977n5jU",
  "DFeXqB6kyBs",
  "fRrAy90I980",
  "Rr0OePU6NN4",
  "Sis8Hn4s8XI",
  "MDMHA8beHpg",
  "3siNPB4fa60",
  "ZOG8Tx-nKX8",
  "Bg0a0bnz0mw",
  "3Zjz0FxxGoQ",
  "XI9Htpx-RyI",
  "QWxaWaLqQ54",
  "uj_SoYZc2RE",
  "kM47Z2d1xcc",
  "eIsSxcQLiuU",
  "s4Ucm8Qp9uU",
  "04r-jQek6jk",
  "8LlfDnKa9mM",
  "gD1lo2oNkrg",
  "wvoVCxJKaOc",
  "gArm7tGr-uw",
  "UBsAqXna0QI",
  "UYHXUylYJy8",
  "4bssDBhLBUk",
  "xJ9PbQHikx4",
  "0XtupTJNQC8",
  "VKgRjFgHPNw",
  "67cOdCYD8qg",
  "-2MkQ2WkWEo",
  "MpJsYFZtQbw",
  "q7mVzgBibaU",
  "zJYcTSQdPBM",
  "G8kHaz5__Ww",
  "8N4v4Fb9k-I",
  "6QP3CIX_Z08",
  "Z6rQuRgbht8",
  "iZ9cfulFLCk",
  "nj-VNOTcAW0",
  "QAZ1XcR-J_A",
  "qflH4-DPxZI",
  "ygS5WaOtm3Y",
  "75p--MXbmJo",
  "rab82k-1XcQ",
  "2It3LYSKyhY",
  "_JzJy3PMXMY",
  "8vM3a7ctFUM",
  "r2IEcrgQZNU",
  "PTrzDcYson4",
  "vRHQqZAlHTs",
  "UNepTmjevxg",
  "CvUl5sf1fjw",
  "RFM5vxxLGbc",
  "AGbOQ8PoXP4",
  "xbsI-xVesVs",
  "y3w5RhUoSYg",
  "5o8_x93BYFw",
  "jByJ6BywK3s",
  "9LW1l4sIFgI",
  "csnZPX5H-y8",
  "X4qqpP4mFFw",
  "LXU5RQOS4jA",
  "EIwGA_ng_Gs",
  "RHR4IQO4e2Y",
  "xd8cHjcUz0U",
  "NcngBJkv-68",
  "aLN9A5HdhQE",
  "6BjuUtk2mFo",
  "5cPNEy41qaI",
  "1dwMqg28HIw",
  "wfWEkzxgvZI",
  "MSD1y8M7ep0",
  "xHx-pPGUH9E",
  "rhw4Y4z2N20",
  "NSCb8ulC_4A",
  "ZpuMfyPiPjc",
  "GKYvNJRf6KI",
  "oWVO5oIfifE",
  "y63ilYpBqG8",
  "D3CBLQ8QJjg",
  "YnDOKUZY2sU",
  "pI1j-OJLhzE",
  "x0PW3D944dk",
  "5l3S9W0gRuY",
  "fzfCQX9NWNI",
  "6rB06sa8Ww0",
  "l8cL7j9oL1o",
  "_ZCyoE4bO0M",
  "kPW2JqK31WQ",
  "Du2wAPQF-WA",
  "K7eOfq-Mtwc",
  "dHW3_DTpNxM",
  "HUF3wCR7KfY",
  "_1blL5z8MQs",
  "x0tvBLzac-Q",
  "qfyfXe8lJN4",
  "N9NSZHSpOdA",
  "Ky-dnbjFruI",
  "5ILRagsmcuA",
  "d1UFZWMvfcU",
  "Q_F44-kKDNc",
  "KR7F-3DCySI",
  "MZrDyzUzLeA",
  "zkpGnscyk8E",
  "V7zV3xCF5vw",
  "nxM-q1_tHYc",
  "rSQAuh7PXVM",
  "QXewxTCMT_o",
  "uhlUbikVqB8",
  "FxSBjSRMwgE",
  "dc2MXuPu0Yw",
  "wKzy-xoO4xA",
  "QPxc5z7czrY",
  "fYV8Eh-oHgk",
  "9FsSyZmQN4I",
  "l_7mVg-cefU",
  "LbKHhM6ltC8",
  "RQZe1l4sWQA",
  "AcL4PMsXDq8",
  "ZRHjvktDuqU",
  "IYYCsj27eZk",
  "XynAE9fn90g",
  "9o78EWigPOc",
  "WRL29NPnmMY",
  "nRk7PW9uqGg",
  "a8nNJoDub2g",
  "cIHrHJuY2pE",
  "d81HX2j4rII",
  "JBe78nikdRA",
  "6KeiL5F3nlk",
  "YYJ68yi_3aY",
  "0RSwTBpSqOY",
  "3xceDpk52xA",
  "h-8aBouP4Qo",
  "2ho3QpCX6sM",
  "yBomug2AQTE",
  "4WluQg6gR-o",
  "vZEn3UOPBq4",
  "uyaX4lRBkwc",
  "SHTTozeaqBY",
  "0wH_-7E_iI0",
  "czuNLQYzGWw",
  "5_p4os_viDg",
  "FqnEmaXkZFc",
  "iQViFCCW5-8",
  "83DraLjCNPg",
  "7Lgk-6Q5Eic",
  "4sstApnRIHU",
  "gGHBF925IMA",
  "5q4khw71mbY",
  "-lUs6pNtRqI",
  "iFS6yv9YPbc",
  "rCDA3cB-O1U",
  "KNjKO2BqP34",
  "totm-VtkdP0",
  "E_RFS0AdZLg",
  "dpBzoIe7yi8",
  "hd18DRUd9YQ",
  "td-3GOF967Q",
  "eGGZ23RPWIs",
  "bmE91LUt3-4",
  "-ywGPbYWOr4",
  "ViyY0wtYtTw",
  "knwsL8-7IsE",
  "mV69SV2ghH0",
  "4Xcjdq5LkmQ",
  "zoBdT4D8Lpg",
  "TqCpuIXC_Wk",
  "Dv3mbPZ4tQI",
  "20NLIVccPf0",
  "mQtbO1AOeCs",
  "bW-UK20Nehg",
  "qHhTrSWnPuo",
  "J58obxxB_vk",
  "YZhve0-Ep2Q",
  "8Tb-CHjgsr8",
  "nIW4kLyVbAA",
  "UGjEY1KtDUo",
  "4Tue-aJ7rh4",
  "_jjUoDXWPDI",
  "yOgaLbJ4CII",
  "IRZtqv0nMCc",
  "wJTKhL1hWUQ",
  "q9djN3MQbzw",
  "b6lNUUdi-vU",
  "enAQOOxUasw",
  "MNpGtkA-lWo",
  "bw7w-K5JJm8",
  "WGCzLfXqIIM",
  "tZBuK9SSnxA",
  "vqzMdWcwSQs",
  "n4NliYU8Oec",
  "mUVwW4eYj3Y",
  "SJ158CpsrXo",
  "SaVECszwwGI",
  "fvUbT6uvH2s",
  "s3QJ3gSWZNk",
  "WAY3vXhirjg",
  "6gNCEodKz2k",
  "Cllq6WvM2TA",
  "mxAiCcb0Xac",
  "mxDW0_uDLE4",
  "fN_MgWzGsCI",
  "V9unZ6Jd318",
  "-GSvmwL_eEU",
  "U5rvZq_QZEY",
  "oADVqvrhygE",
  "CLvAksknvCQ",
  "y-rR25W2388",
  "RfB7rj6ekfs",
  "0215zfIujjM",
  "tKdh5EVQjEE",
  "g0ipCtEH_z0",
  "zNdpFE3QmeA",
  "J4M2LVUEqVw",
  "oF0RvRfAkCQ",
  "dmNrIzQuuxQ",
  "Ecw91cSZu5k",
  "H8VBbgqxbls",
  "kuCxKrcmjfM",
  "QvkdeOofb3U",
  "fNve6tvD1BI",
  "m63J7AlO8Eo",
  "HrjtRqWAyRk",
  "nRLaUDv0QRQ",
  "5ex0GUzfq-k",
  "52sIqc6G5DY",
  "hjNnNKuspbQ",
  "SpqYbPm6pjQ",
  "uY8hz6USA6E",
  "YJgvrQXlRaw",
  "uEA05VPUMOU",
  "0tVXr-rW1pg",
  "KS544mlLz5s",
  "KMTJ43DHz7k",
  "-t37Z1FkcGg",
  "WYmRiPTpVAo",
  "ZFnizww3JJg",
  "-6zpDZ99l4w",
  "54MU6ren1Bw",
  "QhFJNrHvtLg",
  "tZeqaoGMeXw",
  "PwDGl5cOXho",
  "t80VYAlDzxY",
  "FpJjkvD3yxc",
  "MzJor0BgMoA",
  "AkOJV2euVis",
  "hOmiXtkYFC8",
  "O9D-7pCBxMw",
  "8aZPeck1dbE",
  "-1Yu-_zjwoA",
  "G23Ytsruu78",
  "y-DauEqdwjY",
  "CW8pAwrdPQY",
  "WR7Jml3npr8",
  "AoU9lzhiEq4",
  "8PepNE7y7NQ",
  "azu_INLYOXw",
  "a1yX2mE1dpc",
  "gZkulknOJrM",
  "p4lN7el3v2E",
  "GBp3dhvZV10",
  "lBUAlsCaauc",
  "_VRwXLRTXyU",
  "vknikxe8JHo",
  "IMX9CGkqpI4",
  "BlNr_4KdNIE",
  "Z5Fm70MFgZE",
  "uK4WVxyjw3g",
  "HCWadzTxPBo",
  "tvWVquLR5I4",
  "MwRu4iXzdFg",
  "3IEyDngvlGY",
  "KcAWtUcxp1s",
  "B0N_4A-Eh-w",
  "4LTeWd50rYc",
  "A4pCsW4mZlY",
  "aMv2aCTcAC0",
  "5lXEW1FfI8U",
  "A0Vbp2fg2bE",
  "nDyGj3zpvOk",
  "jABkY8hFG38",
  "YPz59nZeYbU",
  "y0t697DiOvk",
  "5k3ClC5n4tg",
  "ZQhZKEliJmQ",
  "xlaBe2tlW_Q",
  "4U6rIZf_G1c",
  "-vpdCwIUeOw",
  "-AXT7rre8xQ",
  "vf6VPrYWFHQ",
  "TmtWITllnvw",
  "ft6SCqc_tow",
  "_SIJwzQWorw",
  "2yyGlDzCMGU",
  "meZ-RqAs8Wc",
  "v9Ldb871uBA",
  "NHV7BBN4B7c",
  "DCki5yk7D-Q",
  "M5CeQG1YfEQ",
  "Mkeeqvv4w50",
  "5lfaq_Yo7Tg",
  "B9QcEBgrvQY",
  "1wRSPZRzcZQ",
  "WPQyX_wq6Ik",
  "qO7-UjxhU1Q",
  "MphznLB38C8",
  "P8aNtZ7xptU",
  "SvAGAU0WsMM",
  "fQb7-hppn30",
  "3Lv8IhtxtZQ",
  "_aY-ET_KoxU",
  "y81K6_0w7Ak",
  "Dyc8-jpsvfQ",
  "Lz3hPMmlZhE",
  "NP_C4ysUkEA",
  "l3tmaKcKivU",
  "H_cHqoP3-oY",
  "W3u6Mau2gQw",
  "X7brAAzwANA",
  "PuyZ_VN6tbk",
  "OXQg57Ow-Fg",
  "NsbxatElE58",
  "Kgcm-qzmUOo",
  "g703OR7Shcw",
  "tHfTktHTSK4",
  "LO90MhLeP0Q",
  "XKKdefAF2dc",
  "2_bR0DVLTqA",
  "Rs0uvkClHvg",
  "GoSmH26AON8",
  "bGPOGtxmoJQ",
  "7B_ToG_0U3k",
  "EaUconYBiDU",
  "M4CJnmwfolw",
  "1MIJCZeRzVw",
  "cCo-SrTlxw8",
  "k6yJVQVI0ME",
  "7el7jm1iJE8",
  "DkQaBtzFoqo",
  "FhijL5NN7Ws",
  "3OyC1D11BJ8",
  "VFMnB-Z4H44",
  "K9HV8GgjCUs",
  "hCmKVQ-vpsI",
  "_hNRpI4-B8I",
  "yZVTRoUFaek",
  "OpNDMT8j884",
  "SbLWooziQLg",
  "TCO7RLHldzE",
  "_LI5jIbE74I",
  "l86yDLw40aU",
  "lxfraBp3lxs",
  "KvoU21m0PSI",
  "aASDLfZTRZg",
  "kn2Vwy6Injo",
  "MWNZ82OWIus",
  "tn7hqdjyq-I",
  "zLEfwQ2lRvk",
  "ksQIsCxiWvw",
  "_wGJAEzTWzY",
  "lGJTsuNCTco",
  "ByDg0yFPxfE",
  "-73oVm7g6Ps",
  "Y3HVoiuzA4M",
  "ooNVGnSwLec",
  "mUUv3xcmvkA",
  "ck0gD8bxNNU",
  "v3KDE-spiz4",
  "YheeZ0DXxHY",
  "-htIwUregfc",
  "UAFfCHBLEQQ",
  "sutvJjJPPDk",
  "JuhyCoygdJo",
  "lAw00GvDKIs",
  "V8l-L9rNdV0",
  "4egIAOPp3Wc",
  "wPOch2G4MMc",
  "CwD_TvePkDw",
  "s2FyNNImGAk",
  "o7a8jZZoSoA",
  "_4PRe-pbYTw",
  "IM3hHviFMk4",
  "kxUQOcaT53g",
  "YY2gX68ikd4",
  "HIYigGOfxfQ",
  "GJaFQG5Jx8Y",
  "Sji2xevrfCw",
  "SvSDS3eh4ns",
  "zU7GWcZfycI",
  "aPjVTy0_ZFA",
  "55snVqj9FuE",
  "RZ7GTg6iknE",
  "jbP4Ska6anw",
  "jlxzZ1ZeshE",
  "EziNkimvsqI",
  "R32Ntt8MOmE",
  "HM2JpZg-Qx0",
  "cehQA7k13Dk",
  "eO4em6oefbM",
  "RDf6oce2a1Y",
  "lHnblr11Np8",
  "b3iKJ10UAnE",
  "EyYj80XEcRk",
  "8JzED4-CVmM",
  "z4FfxwTULqc",
  "BbbcvFJ55F4",
  "DMXORaV1jSQ",
  "rRwzN523UvE",
  "pTDvW2fYNB0",
  "oHksuJShN7k",
  "Be1UyZorMeQ",
  "mr6sTqvpb5I",
  "V5MsM4mUjuk",
  "48sdUzGfjHU",
  "EUunC0kpbYE",
  "KmWtsP2lPOc",
  "M3Ov5ZSk6Nc",
  "hBdixW9QHb4",
  "Pk44fCQzplQ",
  "e_oQY83DbG8",
  "cMFX3jmIB00",
  "hT4YSQSED9Q",
  "ClRU3lWUQTk",
  "pCas579rdZI",
  "dzc1SuIqOzw",
  "F2WdcYG1dFU",
  "vTXbbROfbeo",
  "sixsIHYI25A",
  "PwPRd_NauEk",
  "_7Q2KfDLwQg",
  "BwnPhG4YzAs",
  "_FBlCc7n1-E",
  "ICU9FVo6f5Q",
  "k3VBI1RHRaE",
  "dHd8gdBi3nU",
  "cRLHDRUKzi8",
  "r36YlUpEJLc",
  "kHKM16BN2VQ",
  "RbEtGLfgJjc",
  "78LdPbZyvAI",
  "lYHBV_36gu8",
  "iCLytA1MPAQ",
  "aAoDFB4Y80E",
  "NaoIA7E3q7w",
  "fnH7n7VgqHY",
  "Gtvx0x-bFoA",
  "Oj0iFoKy8fQ",
  "HeQjXwfx2Fk",
  "8xH91WfXVqo",
];
// Extend the window object to add onYouTubePlayerAPIReady
declare global {
  interface Window {
    onYouTubePlayerAPIReady: () => void;
    YT: any; // YouTube API might not be recognized, so we declare it as 'any'
  }
}
export default function App() {
  //let [index, setIndex] = useState(0);
  //let [useKey, setKey] = useState(0);
  let [useEmbed, setUseEmbed] = useState(true);
  let [shuffle, setShuffle] = useState(false);
  let [canonicalIndex, setCanonicalIndex] = useState(0);
  //  const videoarraycanonical = placeholdervideos;

  type VideoMetadata = {
    title: string;
    lastFetched: number;
  };

  const LOCAL_STORAGE_KEY = "videoMetadata";

  function loadVideoMetadata(): Record<string, VideoMetadata> {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    return data ? JSON.parse(data) : {};
  }

  function saveVideoMetadata(data: Record<string, VideoMetadata>): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  }

  // Asynchronous function to fetch metadata and update localStorage if necessary
  async function checkAndFetchMetadata(videoId: string): Promise<void> {
    let metadata = loadVideoMetadata();

    if (!metadata[videoId] || !metadata[videoId].title) {
      console.log("Fetching metadata for video ID:", videoId);
      // Start timing before the video title fetch
      const startTime = Date.now();

      const title = await getVideoTitle(videoId);

      // End timing after the video title fetch
      const fetchDuration = Date.now() - startTime;

      // Calculate the remaining time to wait (if any)
      const remainingTime = 500 - fetchDuration;

      // If fetching took less than 0.5 seconds, wait for the remaining time
      if (remainingTime > 0) {
        await wait(remainingTime);
      }
      if (title) {
        console.log("Fetched metadata for video ID:", videoId, "Title:", title);
        metadata[videoId] = {
          title,
          lastFetched: Date.now(),
        };
        saveVideoMetadata(metadata);
      }

      // Trigger a 'storage' event to inform all components of the update
      window.dispatchEvent(new Event("storage"));
    }
  }

  function getMetadataForVideo(videoId: string): VideoMetadata | null {
    const metadata = loadVideoMetadata();
    return metadata[videoId] || null;
  }

  // Wait function that pauses execution for a given number of milliseconds
  function wait(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Utility function to initialize local storage
  function initializeVideoIds() {
    if (!localStorage.getItem("knownVideoIds")) {
      localStorage.setItem("knownVideoIds", JSON.stringify([]));
    }
  }

  // Function to import an array of video IDs into local storage
  function addVideoIds(newVideoIds: string[]) {
    initializeVideoIds();

    // Retrieve existing video IDs from local storage
    const knownVideoIds = JSON.parse(
      localStorage.getItem("knownVideoIds") || "[]"
    ) as string[];

    // Create a Set from existing IDs to easily check for duplicates
    const knownVideoIdSet = new Set(knownVideoIds);

    // Add new IDs to the Set (duplicate entries will be ignored)
    newVideoIds.forEach((id) => knownVideoIdSet.add(id));

    // Update local storage with the new list
    localStorage.setItem(
      "knownVideoIds",
      JSON.stringify(Array.from(knownVideoIdSet))
    );
  }

  // Function to get the list of known video IDs
  function getKnownVideoIds(): string[] {
    initializeVideoIds();

    // Retrieve and parse the list of video IDs from local storage
    const knownVideoIds = JSON.parse(
      localStorage.getItem("knownVideoIds") || "[]"
    ) as string[];
    return knownVideoIds;
  }

  function Uhshit() {
    const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(progress); // Use ref to persist progress across renders
    const isFetchingRef = useRef(false); // Ref to track if fetch is already running

    async function placeholderything() {
      if (isFetchingRef.current) return; // Prevent multiple runs

      isFetchingRef.current = true; // Mark fetching as started

      for (let i = 0; i < useVideoArray.length; i++) {
        let knownvideoids = getKnownVideoIds();
        await checkAndFetchMetadata(knownvideoids[i]);
        console.log("Done for", i);

        const newProgress = ((i + 1) / useVideoArray.length) * 100;
        progressRef.current = newProgress; // Persist progress in ref
        setProgress(newProgress); // Update the state for rendering
      }

      isFetchingRef.current = false; // Mark fetching as completed
    }
    function onclick() {
      placeholderything();
      addVideoIds(useVideoArray);
    }
    return (
      <>
        <Progress className="w-32" value={progress} />
        <Button variant="outline" size="sm" onClick={() => onclick()}>
          <Share2 className="mr-2 h-4 w-4" />
          Fetch Titles
        </Button>
      </>
    );
  }

  async function waittesst() {
    for (let i = 0; i < 10000; i++) {
      await wait(500);
      console.log("Done for", i);
    }
  }

  async function getVideoTitle(videoId: string) {
    try {
      //const cachedData = localStorage.getItem(videoId);
      // if (cachedData) {
      //   const { title } = JSON.parse(cachedData);
      //   console.log("Using cached data for video ID:", videoId);
      //   if (title == "- YouTube") {
      //     //  implement blacklisting later
      //     //    blacklistskip(videoId);
      //   }
      //   return title;
      // }

      const proxyUrl = `https://rennet.whey.party/https://youtube.com/watch?v=${videoId}`;
      console.log(proxyUrl);
      let title;

      await fetch(proxyUrl)
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");

          // Get OpenGraph/Twitter Card data
          //title = doc.querySelector("meta[property='og:title']")?.content || doc.title;
          const metaTitle = doc.querySelector(
            "meta[property='og:title']"
          ) as HTMLMetaElement | null;
          title = metaTitle?.content || doc.title;

          if (title == "- YouTube") {
            //    blacklistskip(videoId);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      if (!title) {
        console.error("Could not extract title from the given YouTube video.");
        return null;
      }

      //localStorage.setItem(videoId, JSON.stringify({ title }));
      return title;
    } catch (error: any) {
      console.error(
        `Error fetching video metadata for video ID ${videoId}: ${error.message}`
      );
      return null;
    }
  }
  function QueueItem({
    indexiom,
    shouldFetch,
    onFetchNext,
  }: {
    indexiom: number;
    shouldFetch: boolean;
    onFetchNext: () => void;
  }) {
    const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
    if (shouldFetch) console.log("QueueItem rendered" + indexiom, shouldFetch);

    // Function to read from localStorage and update the state
    let videoId = useVideoArray[indexiom];

    // Sync the state with localStorage when component mounts
    const [title, setTitle] = useState<string | null>(null);
    useEffect(() => {
      const updateTitleFromStorage = () => {
        const metadata = getMetadataForVideo(videoId);
        if (metadata) {
          setTitle(metadata.title);
        } else {
          setTitle(null); // If no title, clear the state
        }
      };

      updateTitleFromStorage(); // On initial load

      // Listen to storage changes and update when localStorage is modified
      const handleStorageChange = () => {
        updateTitleFromStorage();
      };

      window.addEventListener("storage", handleStorageChange);

      // Cleanup listener when component unmounts
      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }, [videoId]);

    const itemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (useIndex === indexiom && itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, [useIndex, indexiom]);

    return (
      <div
        ref={itemRef}
        key={indexiom}
        className={`flex cursor-pointer hover:bg-accent/75 rounded-md w-[400px] ${
          useIndex === indexiom ? "bg-accent" : ""
        }`}
      >
        <ContextMenu>
          <ContextMenuTrigger className="">
            <div
              className="flex items-start space-x-3 m-2"
              onClick={() => setIndex(indexiom)}
            >
              <img
                alt={`Thumbnail ${indexiom + 1}`}
                className="rounded object-cover"
                height="63"
                src={`https://i.ytimg.com/vi/${useVideoArray[indexiom]}/default.jpg`}
                style={{
                  aspectRatio: "16/9",
                  objectFit: "cover",
                }}
                width="112"
              />
              <div className="space-y-1">
                <h4 className="text-sm font-medium leading-none">
                  {title ? title : `Loading Video ${indexiom + 1}`}
                </h4>
                <p className="text-xs text-muted-foreground">
                  Description for Video {indexiom + 1}
                </p>
              </div>
            </div>
          </ContextMenuTrigger>
          <ContextMenuThingy indexiom={indexiom} />
        </ContextMenu>
      </div>
    );
  }

  function Queue() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
    // const [localkey, setlocalkey] = useState(0);
    // setlocalkey(useKey + 1);
    // setKey(localkey);

    const handleFetchNext = () => {
      // Move to the next item to trigger the next fetch
      setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    return (
      <ScrollArea className="h-[calc(100vh-9rem)] gap-1">
        <div className="flex flex-col gap-1">
          {useVideoArray.map((_: any, i: number) => (
            <QueueItem
              key={i}
              indexiom={i}
              shouldFetch={i === currentIndex}
              onFetchNext={handleFetchNext}
            />
          ))}
        </div>
      </ScrollArea>
    );
  }

  async function fetchWithRetries(url: string, retries = 3, delay = 300) {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url);
        if (response.ok) {
          return response;
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    throw new Error("Failed to fetch with retries");
  }

  async function fetchInvidiousDomains() {
    const response = await fetch(
      "https://api.invidious.io/instances.json?pretty=1&sort_by=type,users"
    );
    if (!response.ok) {
      throw new Error("Failed to fetch Invidious instances data");
    }
    const data = await response.json();

    // Filter out domains where "api" is true
    const apiDomains = data
      .filter(([domain, info]: [string, any]) => info.api === true)
      .map(([domain, info]: [string, any]) => domain);

    return apiDomains;
  }

  async function fetchStreamingUrlFromInvidious(
    videoId: string,
    proxyUrl: string,
    invidiousDomains: string[]
  ) {
    console.log("fetchStreamingUrlFromInvidious has been CALLED !! :)");
    const randomDomain =
      invidiousDomains[Math.floor(Math.random() * invidiousDomains.length)];
    const apiUrl = `https:/${randomDomain}/api/v1/videos/${videoId}`;
    const response = await fetchWithRetries(apiUrl);
    if (response.ok) {
      const data = await response.json();
      for (const format of data.adaptiveFormats) {
        if (format.audioQuality === "AUDIO_QUALITY_MEDIUM") {
          return proxyUrl + format.url;
        }
      }
    }
    console.error("Failed to fetch Invidious sourced URL");
    return null;
  }

  async function getStreamingUrl(mediaId: string, mediaType: string) {
    console.log("Fetching streaming URL for", mediaId);
    const proxyUrl = "https://rennet.whey.party/";

    // Check if URL for the mediaId and mediaType is cached
    // if (streamingUrlsCache.has(mediaId) && streamingUrlsCache.get(mediaId).has(mediaType)) {
    //     const cachedUrl = streamingUrlsCache.get(mediaId).get(mediaType);
    //     if (cachedUrl !== null && cachedUrl !== "") {
    //         console.log('Retrieving streaming URL from cache');
    //         return cachedUrl;
    //     } else {
    //         // If URL in cache is null or empty, remove it from cache
    //         streamingUrlsCache.get(mediaId).delete(mediaType);
    //     }
    // }

    try {
      const method =
        mediaType === "Video" ? "getDecipheredUrl" : "getDecipheredAudioUrl";
      // Attempt fetching the audio URL with indices from 1 up to 5
      if (method !== "getDecipheredUrl") {
        for (let indexiom = 1; indexiom <= 5; indexiom++) {
          const response = await fetchWithRetries(
            `https://rennet.whey.party/https://cf-worker-youtubei.rileymarsh1337.workers.dev/${method}/${mediaId}/${indexiom}`
          );
          console.log("trying index", indexiom);
          if (response.ok) {
            const data = await response.text();
            const streamingUrl = data.replace(/^"(.*)"$/, "$1");
            console.log("Stream URL:", streamingUrl);
            // Check if streamingUrl is empty, "", or null
            if (!streamingUrl || streamingUrl === "" || streamingUrl === null) {
              console.error("Streaming URL is empty, falling back...");
            } else {
              // Store the streaming URL in cache
              // if (!streamingUrlsCache.has(mediaId)) {
              //     streamingUrlsCache.set(mediaId, new Map());
              // }
              // streamingUrlsCache.get(mediaId).set(mediaType, streamingUrl);
              return streamingUrl;
            }
          }
        }
      } else {
        const response = await fetchWithRetries(
          `https://rennet.whey.party/https://cf-worker-youtubei.rileymarsh1337.workers.dev/${method}/${mediaId}`
        );
        if (response.ok) {
          const data = await response.text();
          const streamingUrl = data.replace(/^"(.*)"$/, "$1");
          console.log("Stream URL:", streamingUrl);
          // Check if streamingUrl is empty, "", or null
          if (!streamingUrl || streamingUrl === "" || streamingUrl === null) {
            console.error("Streaming URL is empty, falling back...");
          } else {
            // Store the streaming URL in cache
            // if (!streamingUrlsCache.has(mediaId)) {
            //     streamingUrlsCache.set(mediaId, new Map());
            // }
            // streamingUrlsCache.get(mediaId).set(mediaType, streamingUrl);
            return streamingUrl;
          }
        }
      }
      // Log an error if all retries fail
      console.error("Failed to fetch streaming URL after multiple attempts.");
    } catch (error) {
      console.error("Error occurred:", error);
    }

    if (mediaType === "Audio") {
      console.log("Trying video URL for audio");
      //const streamingUrl = await getStreamingUrl(mediaId, 'Video');
      try {
        const method = "getDecipheredUrl";
        const response = await fetchWithRetries(
          `https://rennet.whey.party/https://cf-worker-youtubei.rileymarsh1337.workers.dev/${method}/${mediaId}`
        );

        if (response.ok) {
          const data = await response.text();
          const streamingUrl = data.replace(/^"(.*)"$/, "$1");
          console.log("Stream URL:", streamingUrl);

          // Store the streaming URL in cache
          // if (!streamingUrlsCache.has(mediaId)) {
          //     streamingUrlsCache.set(mediaId, new Map());
          // }
          // streamingUrlsCache.get(mediaId).set(mediaType, streamingUrl);
          // Check if streamingUrl is empty, "", or null
          if (!streamingUrl || streamingUrl === "" || streamingUrl === null) {
            console.error("Streaming URL is empty, falling back...");
          } else {
            return streamingUrl;
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }

    console.log("Trying Invidious");
    const invidiousDomains = await fetchInvidiousDomains();
    if (invidiousDomains.length === 0) {
      console.error("No Invidious instances with API support found.");
    }

    try {
      const streamingUrl = await fetchStreamingUrlFromInvidious(
        mediaId,
        proxyUrl,
        invidiousDomains
      );
      console.log("got from invidious: ", streamingUrl);
      if (streamingUrl) {
        if (!streamingUrl || streamingUrl === "" || streamingUrl === null) {
          console.error("Streaming URL is empty, falling back...");
        } else {
          // Store the streaming URL in cache
          // if (!streamingUrlsCache.has(mediaId)) {
          //     streamingUrlsCache.set(mediaId, new Map());
          // }
          // streamingUrlsCache.get(mediaId).set(mediaType, streamingUrl);
          return streamingUrl;
        }
      }
    } catch (error) {
      console.error("Error loading audio:", error);
    }

    // Retry fetching the URL if not obtained
    console.log("Failed to fetch streaming URL. Falling back to fiddlesticks");
    //return await getStreamingUrl(mediaId, mediaType);
    return "/fallback.opus";
  }

  // imported from old vidplayer
  function ContextMenuThingy({ indexiom }: { indexiom: number }) {
    const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
    function moveEntryToNextIndex(index: number) {
      console.log("before index: " + useIndex);
      console.log("pressed item: " + index);
      let videoIds = [...useVideoArray];

      if (useIndex === index) {
        return;
      } //im new
      console.log("moveEntryToNextIndex index" + index);

      // Check if the provided index is within the bounds of the array
      if (index < 0 || index >= videoIds.length - 1 || index === useIndex) {
        console.log("Index is out of bounds or cannot move further.");
        return;
      }

      // Get the item to be moved
      var itemToMove = videoIds[index];

      // Remove the item from its current position
      videoIds.splice(index, 1);

      // Calculate the new index to insert the item
      var newIndex = useIndex < index ? useIndex + 1 : useIndex;

      // Insert the item at the new index
      videoIds.splice(newIndex, 0, itemToMove);

      // Log the updated array
      console.log("Updated videoids array:", videoIds);

      let newerindex = useIndex;

      if (index <= useIndex) {
        newerindex = Math.max(useIndex - 1, 0);
      }
      console.log("after index newerindex: " + newerindex);
      // Update the display or perform any other action as needed
      //prefetchNextStreamingUrl(currentIndex, currentMediaType);
      //initDisplayArray();
      //selectButton();
      setVideoArray(videoIds);
      setIndex(newerindex);
      console.log("after index useIndex: " + useIndex);
    }
    function deleteEntry(index: number) {
      let videoIds = [...useVideoArray];
      let currentindexcache = useIndex;

      console.log("deleteEntry index" + index);

      // Check if the provided index is within the bounds of the array
      if (index < 0 || index >= videoIds.length) {
        console.log("Index is out of bounds.");
        return;
      }

      // Remove the item at the specified index
      videoIds.splice(index, 1);

      // Log the updated array
      console.log("Updated videoids array:", videoIds);
      let newerindex = useIndex;

      if (index <= useIndex) {
        newerindex = Math.max(useIndex - 1, 0);
      }
      // Update the display or perform any other action as needed
      //initDisplayArray();
      //selectButton();
      if (currentindexcache === index) {
        newerindex = Math.max(useIndex + 1, 0);
        //loadNextVideo();
      } //im new
      setVideoArray(videoIds);
      setIndex(newerindex);
    }
    function moveEntryUp(index: number) {
      let videoIds = [...useVideoArray];
      // Check if the provided index is within the bounds of the array
      if (index <= 0 || index >= videoIds.length) {
        console.log("Index is out of bounds or cannot move further up.");
        return;
      }

      // Swap the item at the provided index with the item above it
      var temp = videoIds[index];
      videoIds[index] = videoIds[index - 1];
      videoIds[index - 1] = temp;

      let newerindex = useIndex;

      // If currentIndex is being swapped, update its value
      if (useIndex === index) {
        newerindex--;
      } else if (useIndex === index - 1) {
        newerindex++;
      }

      // Log the updated array
      console.log("Updated videoids array (moved up):", videoIds);

      // Update the display or perform any other action as needed
      //initDisplayArray();
      //selectButton();
      setVideoArray(videoIds);
      setIndex(newerindex);
    }

    function moveEntryDown(index: number) {
      let videoIds = [...useVideoArray];
      // Check if the provided index is within the bounds of the array
      if (index < 0 || index >= videoIds.length - 1) {
        console.log("Index is out of bounds or cannot move further down.");
        return;
      }

      // Swap the item at the provided index with the item below it
      var temp = videoIds[index];
      videoIds[index] = videoIds[index + 1];
      videoIds[index + 1] = temp;

      let newerindex = useIndex;

      // If currentIndex is being swapped, update its value
      if (useIndex === index) {
        newerindex++;
      } else if (useIndex === index + 1) {
        newerindex--;
      }

      // Log the updated array
      console.log("Updated videoids array (moved down):", videoIds);

      // Update the display or perform any other action as needed
      //initDisplayArray();
      //selectButton();
      setVideoArray(videoIds);
      setIndex(newerindex);
    }

    return (
      <ContextMenuContent className="w-64">
        <ContextMenuItem inset onClick={() => moveEntryToNextIndex(indexiom)}>
          moveEntryToNextIndex
          <ContextMenuShortcut>⌘[</ContextMenuShortcut>
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem inset onClick={() => moveEntryUp(indexiom)}>
          moveEntryUp
          <ContextMenuShortcut>⌘R</ContextMenuShortcut>
        </ContextMenuItem>
        <ContextMenuItem inset onClick={() => moveEntryDown(indexiom)}>
          moveEntryDown
          <ContextMenuShortcut>⌘R</ContextMenuShortcut>
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem inset onClick={() => deleteEntry(indexiom)}>
          deleteEntry
          <ContextMenuShortcut>⌘]</ContextMenuShortcut>
        </ContextMenuItem>
        {/* <ContextMenuSub>
          <ContextMenuSubTrigger inset>More Tools</ContextMenuSubTrigger>
          <ContextMenuSubContent className="w-48">
            <ContextMenuItem>
              Save Page As...
              <ContextMenuShortcut>⇧⌘S</ContextMenuShortcut>
            </ContextMenuItem>
            <ContextMenuItem>Create Shortcut...</ContextMenuItem>
            <ContextMenuItem>Name Window...</ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem>Developer Tools</ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSeparator />
        <ContextMenuCheckboxItem checked>
          Show Bookmarks Bar
          <ContextMenuShortcut>⌘⇧B</ContextMenuShortcut>
        </ContextMenuCheckboxItem>
        <ContextMenuCheckboxItem>Show Full URLs</ContextMenuCheckboxItem>
        <ContextMenuSeparator />
        <ContextMenuRadioGroup value="pedro">
          <ContextMenuLabel inset>People</ContextMenuLabel>
          <ContextMenuSeparator />
          <ContextMenuRadioItem value="pedro">
            Pedro Duarte
          </ContextMenuRadioItem>
          <ContextMenuRadioItem value="colm">Colm Tuite</ContextMenuRadioItem>
        </ContextMenuRadioGroup> */}
      </ContextMenuContent>
    );
  }

  function MainArea() {
    //const { useIndex, setIndex } = useVideo();
    const { useVideoId }: { useVideoId: string } = useVideo();
    function VidPlayer({ videoid }: { videoid: string }) {
      //let videoid: string = useVideoArray[useIndex];
      // function Example() {
      //   const onPlayerReady: YouTubeProps['onReady'] = (event) => {
      //     // access to player in all event handlers via event.target
      //     event.target.playVideo();
      //   }

      //   let opts: YouTubeProps['opts'] = {
      //     height: '100%',
      //     width: '100%',
      //     videoId: videoid,
      //     playerVars: {
      //       // https://developers.google.com/youtube/player_parameters
      //       autoplay: 1,
      //       controls: 1,
      //     },
      //   };

      //   return <YouTube style={{width: "100%", height: "100%"}} videoId={videoid} opts={opts} onEnd={() => setIndex(useIndex + 1)}/>;
      // }

      // const YouTubePlayer: React.FC = () => {
      //   const playerRef = useRef<HTMLDivElement | null>(null);

      //   useEffect(() => {
      //     // Define a function to handle the API loading and player creation
      //     const initializeYouTubePlayer = () => {
      //       if (window.YT && playerRef.current) {
      //         new window.YT.Player(playerRef.current, {
      //           height: '100%',
      //           width: '100%',
      //           videoId: {videoid}, // Static video ID, can be changed directly here
      //         });
      //       }
      //     };

      //     // Load the YouTube IFrame Player API asynchronously
      //     const tag = document.createElement('script');
      //     tag.src = 'https://www.youtube.com/player_api';
      //     const firstScriptTag = document.getElementsByTagName('script')[0];
      //     firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

      //     // Set up the callback for when the API is ready
      //     window.onYouTubePlayerAPIReady = initializeYouTubePlayer;

      //     // Cleanup function to remove the script if component unmounts
      //     return () => {
      //       const ytScript = document.querySelector('script[src="https://www.youtube.com/player_api"]');
      //       if (ytScript) {
      //         ytScript.remove();
      //       }
      //     };
      //   }, []); // Empty dependency array ensures this runs once when the component mounts

      //   return (
      //     <div>
      //       <div ref={playerRef} id="ytplayer"></div>
      //     </div>
      //   );
      // };
      // function handleVideoEnd() {
      //   setIndex(useIndex + 1);
      // }
      // const videoArrayRef = useRef(useVideoArray);
      // const indexRef = useRef(useIndex);

      // // Update the refs with the current values on the first render
      // useEffect(() => {
      //   videoArrayRef.current = useVideoArray;
      //   indexRef.current = useIndex;
      // }, []);
      const { useIndex, setIndex }: { useIndex: number; setIndex: any } =
        useVideo();

      if (useEmbed === false) {
        // return (
        //   <>
        //     <YouTubeVideo
        //       queue={videoArrayRef.current}
        //       index={indexRef.current}
        //       setIndex={() => setIndex()}
        //       onEnd={() => handleVideoEnd()}
        //     />
        //     <div>
        //       {" "}
        //       hii this player is very much in beta and youd better off using
        //       ReactPlayer instead for the mean while. This self built player
        //       isnt even seamless yet. Thanks for trying out this new iteration
        //       of VidPlayer !
        //     </div>
        //   </>
        // );
        // return (
        //   <YouTubePlayer
        //   videoQueue={useVideoArray}
        //   currentIndex={useIndex}
        //   onVideoEnd={handleVideoEnd} />
        // )
        // return (
        //   <iframe
        //     id="video-placeholder"
        //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        //     title="Video"
        //     width="100%"
        //     height="100%"
        //     src={`https://www.youtube.com/embed/${videoid}?autoplay=1&amp;controls=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fyoutube-playlist-randomizer.bitbucket.io&amp;widgetid=1`}
        //   ></iframe>
        // );
        // getStreamingUrl(videoid, "Video").then((url) => {
        //   return (
        //     <>
        //       <audio src={url} />
        //       <div>non embed lmao</div>
        //     </>
        //   );
        // });
      } else {
        return (
          <ReactPlayer
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            url={"https://www.youtube.com/watch?v=" + videoid}
            playing={true}
            controls={true}
            onEnded={() => setIndex(useIndex + 1)}
          />
        );
      }
      return null;
    }

    function Title() {
      const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
      const [title, setTitle] = useState<string | null>(null);
      useEffect(() => {
        const updateTitleFromStorage = () => {
          const metadata = getMetadataForVideo(useVideoArray[useIndex]);
          if (metadata) {
            setTitle(metadata.title);
          } else {
            setTitle(null); // If no title, clear the state
          }
        };

        updateTitleFromStorage(); // On initial load

        // Listen to storage changes and update when localStorage is modified
        const handleStorageChange = () => {
          updateTitleFromStorage();
        };

        window.addEventListener("storage", handleStorageChange);

        // Cleanup listener when component unmounts
        return () => {
          window.removeEventListener("storage", handleStorageChange);
        };
      }, [useIndex]);
      return <h2 className="text-2xl font-bold break-all">{title}</h2>;
    }

    //let videoid = useVideoArray[useIndex];

    function Buttons() {
      const { useIndex, setIndex }: { useIndex: number; setIndex: any } =
        useVideo();
      return (
        <div className="flex flex-row gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setIndex(useIndex - 1)}
          >
            <SkipBack className="mr-2 h-4 w-4" />
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setIndex(useIndex + 1)}
          >
            <SkipForward className="mr-2 h-4 w-4" />
            Skip
          </Button>
        </div>
      );
    }

    return (
      <div className="flex-1 p-6 space-y-6">
        <div className="aspect-video bg-muted rounded-lg overflow-hidden">
          {/* <video
          className="w-full h-full object-cover"
          src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          controls
        /> */}
          <VidPlayer videoid={useVideoId} />
        </div>
        <div className="space-y-2">
          <div className="flex flex-row justify-between items-start">
            <Title />
            <Buttons />
          </div>
          <p className="text-muted-foreground">
            Big Buck Bunny tells the story of a giant rabbit with a heart bigger
            than himself. When one sunny day three rodents rudely harass him,
            something snaps... and the rabbit ain&apos;t no bunny anymore! In
            the typical cartoon tradition he prepares the nasty rodents a
            comical revenge.
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <Button variant="outline" size="sm">
            <ThumbsUp className="mr-2 h-4 w-4" />
            Like
          </Button>
          <Button variant="outline" size="sm">
            <ThumbsDown className="mr-2 h-4 w-4" />
            Dislike
          </Button>
          <Button variant="outline" size="sm">
            <Share2 className="mr-2 h-4 w-4" />
            Share
          </Button>
          <Button variant="outline" size="sm" onClick={() => waittesst()}>
            <Share2 className="mr-2 h-4 w-4" />
            Wait test :/
          </Button>
        </div>
      </div>
    );
  }

  function ShuffleContext() {
    const { useIndex, setIndex, useVideoArray, setVideoArray } = useVideo();
    function doshuffle() {
      let indexcache = useIndex;
      let localcachearray = shuffleArray(useVideoArray);
      if (shuffle === true) {
        setShuffle(false);
        setIndex(canonicalIndex);
        setVideoArray(placeholdervideos);
      } else {
        setShuffle(true);
        setIndex(0);
        setCanonicalIndex(indexcache);
        setVideoArray(localcachearray);
      }
    }

    function shuffleArray(array: any[]) {
      const newArray = array.slice(); // Create a shallow copy of the original array
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    }
    return (
      <Switch
        id="shuffle-mode"
        checked={shuffle}
        onCheckedChange={() => doshuffle()}
      />
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <header className="flex items-center justify-between px-6 py-4 bg-background border-b">
        <div className="flex items-center space-x-4">
          <PlaySquare className="h-8 w-8 text-primary" />
          <h1 className="text-2xl font-bold">VidPlayer Neue</h1>
          <Uhshit />
        </div>
        <nav className="flex items-center space-x-4">
          <Input
            className="w-64"
            placeholder="Search videos..."
            type="search"
          />
          <Button variant="ghost">Import</Button>
          <Button variant="ghost">Playlists</Button>
          <div className="flex items-center space-x-2">
            <Switch
              id="airplane-mode"
              checked={useEmbed}
              onCheckedChange={() => setUseEmbed(!useEmbed)}
            />
            <Label htmlFor="airplane-mode">
              Use ReactPlayer (needs window focus)
            </Label>
          </div>
        </nav>
      </header>
      <main className="flex-1 flex max-w-[1849px] self-center">
        <MainArea />
        <div className="w-[430px] border-l p-2">
          <div className="flex flex-row flex-1 justify-between items-center mx-2 mb-4 mt-2">
            <h3 className="font-semibold">Queue</h3>
            <div className="flex items-center gap-2">
              <ShuffleContext />
              <Label htmlFor="shuffle-mode">Shuffle</Label>
            </div>
          </div>
          <Queue />
          {/* <ScrollArea className="h-[calc(100vh-10rem)]">
            {useVideoArray.map((_, i) => (
              <div key={i} className="" onClick={() => setIndex(i)}>
                <ContextMenu>
                  <ContextMenuTrigger className="">
                    <div className="flex items-start space-x-4 mb-4">
                      <img
                        alt={`Thumbnail ${i + 1}`}
                        className="rounded object-cover"
                        height="63"
                        //src="/placeholder.svg"
                        src={`https://i.ytimg.com/vi/${useVideoArray[i]}/default.jpg`}
                        style={{
                          aspectRatio: "16/9",
                          objectFit: "cover",
                        }}
                        width="112"
                      />
                      <div className="space-y-1">
                        <h4 className="text-sm font-medium leading-none">
                          (Video {i + 1})
                        </h4>
                        <p className="text-xs text-muted-foreground">
                          Description for Video {i + 1}
                        </p>
                      </div>
                    </div>
                  </ContextMenuTrigger>
                  <ContextMenuContent className="w-64">
                    <ContextMenuItem inset>
                      Back
                      <ContextMenuShortcut>⌘[</ContextMenuShortcut>
                    </ContextMenuItem>
                    <ContextMenuItem inset disabled>
                      Forward
                      <ContextMenuShortcut>⌘]</ContextMenuShortcut>
                    </ContextMenuItem>
                    <ContextMenuItem inset>
                      Reload
                      <ContextMenuShortcut>⌘R</ContextMenuShortcut>
                    </ContextMenuItem>
                    <ContextMenuSub>
                      <ContextMenuSubTrigger inset>
                        More Tools
                      </ContextMenuSubTrigger>
                      <ContextMenuSubContent className="w-48">
                        <ContextMenuItem>
                          Save Page As...
                          <ContextMenuShortcut>⇧⌘S</ContextMenuShortcut>
                        </ContextMenuItem>
                        <ContextMenuItem>Create Shortcut...</ContextMenuItem>
                        <ContextMenuItem>Name Window...</ContextMenuItem>
                        <ContextMenuSeparator />
                        <ContextMenuItem>Developer Tools</ContextMenuItem>
                      </ContextMenuSubContent>
                    </ContextMenuSub>
                    <ContextMenuSeparator />
                    <ContextMenuCheckboxItem checked>
                      Show Bookmarks Bar
                      <ContextMenuShortcut>⌘⇧B</ContextMenuShortcut>
                    </ContextMenuCheckboxItem>
                    <ContextMenuCheckboxItem>
                      Show Full URLs
                    </ContextMenuCheckboxItem>
                    <ContextMenuSeparator />
                    <ContextMenuRadioGroup value="pedro">
                      <ContextMenuLabel inset>People</ContextMenuLabel>
                      <ContextMenuSeparator />
                      <ContextMenuRadioItem value="pedro">
                        Pedro Duarte
                      </ContextMenuRadioItem>
                      <ContextMenuRadioItem value="colm">
                        Colm Tuite
                      </ContextMenuRadioItem>
                    </ContextMenuRadioGroup>
                  </ContextMenuContent>
                </ContextMenu>
              </div>
            ))}
          </ScrollArea> */}
        </div>
      </main>
    </div>
  );
}
