// VideoContext.js
import React, { createContext, useState, useContext } from 'react';

const VideoContext = createContext();
const placeholdervideos = [
  "-_HgDQ9LETA",
  "T240sOIer0o",
  "EHg1WLqrZOU",
  "Da-8GHelH0s",
  "gdYARv2J_fI",
  "yHFnIgCoYmo",
  "F_WWad2YRqw",
  "lwU2ficlHrM",
  "E-H9D1x5YOk",
  "IA4-hDHQnqw",
  "bL3baWc-u0Q",
  "WKDNxgQ7XaE",
  "NW559NfkZgk",
  "DTMb70eqZrU",
  "lDt2JNbHCqc",
  "RbvSg9-MOQ8",
  "Y57_WEMz9i4",
  "O6J7C_rLb1U",
  "cugalhvs7B8",
  "yGZr98GEs0U",
  "R1MECfpUfag",
  "6nT3_DP2Uuw",
  "spJP8KbVvU4",
  "-jIDYImzhfA",
  "Bkme1PubX-4",
  "EyNd_iugV-s",
  "UxksoExrJB0",
  "998HstA76_M",
  "4PNPcRDAm0w",
  "r8KPGojTi1E",
  "klVaXvGRKD8",
  "bcnDRGSs5EI",
  "Axt2cOaOUtA",
  "TrILVVwWR6M",
  "ttz6JwzLvK0",
  "xO2aYG4jFiQ",
  "jNptyfJtuQw",
  "kH0P8bIng-U",
  "WqBkN6dZggI",
  "cH3jgInoD3U",
  "QjaHVJQcQVs",
  "Ygai9LRmWG4",
  "plRShIfFupc",
  "8Ql0pWw9tA4",
  "jswnNviYMnU",
  "k9l5VKgoOME",
  "OG_e8ve0l_w",
  "4TDJMwTNv0w",
  "ZxBFoWXMX80",
  "5w-PGrXtmW8",
  "L79ZNHr12pM",
  "Z8v-jBVc9pk",
  "SMXIwsoSqLY",
  "xTg6S5CrDqA",
  "zU0f04F6HLY",
  "aTK4DCTqrMI",
  "PIRAdd_Mo20",
  "_W2CzvNJcHY",
  "3YW9zJGxeWk",
  "U010scclC0E",
  "O9P_3WmknZE",
  "qqQuqqcL_-U",
  "uAIMCHSqSAg",
  "R0xdSLfYxq8",
  "f-aL5kH3lD0",
  "F6iZtodJnCY",
  "e6UW1sVyJcs",
  "NkthosMDNoQ",
  "8MMNh8y2mHM",
  "A5dh9aBXYyk",
  "rkX9HWVwqPQ",
  "nzfFxkG4bnY",
  "MEtKd9v9K2U",
  "Qn7lOZf_xGM",
  "bpJLEpsEd84",
  "W4AcveHnDzg",
  "6voXg6DsZV8",
  "jIebXtezLy8",
  "gV_c88yH2O8",
  "Hp9ihTf4aa0",
  "yTC1VrCibfM",
  "jyXBngR-Ysc",
  "q28tKJPHts4",
  "K6cyZaoFhHg",
  "3eYyC5VdftU",
  "M1fioflxEH0",
  "ld1kprbSNZU",
  "w6HZr6tWb0U",
  "m8TmubqRiyw",
  "jerwwYPq2Fo",
  "lpjYooyv500",
  "3IjgI-Ms1pw",
  "peA0L9dwaHE",
  "7zQIhiYjmD4",
  "GvUCyQPx_kI",
  "PgCknkwzDwc",
  "iA0sXUOAK5A",
  "0tGZ02VHQRc",
  "bNMOSdp0mb8",
  "eHZYffwbVrw",
  "gsmVqtBLtAw",
  "6GCMkfxUvOg",
  "3YaUvO3PrRw",
  "LUqFYnFQhcI",
  "aTFeIa5iyxg",
  "Y6dRsllCWKU",
  "0ppfzG1oVj0",
  "JYvOMW1KRJ0",
  "NplJ1ciVpD4",
  "-nFpwubfS0E",
  "4Q7QV5wB0Ps",
  "9qhg88_FIzM",
  "wNdkdWwEWQc",
  "bPyMQRcKAcE",
  "x_a5E6JxjxM",
  "FkVdFNsbu4E",
  "qrDn6MvKN30",
  "A5TXomTxPA8",
  "61HcB3nw9Ag",
  "i9qzVYM9TTA",
  "15WXquLrops",
  "-_PNSAogPFY",
  "NmmGR7hmiOc",
  "k6EdYyV8T_A",
  "iRFC6JsPUrY",
  "awM4aBYXQ68",
  "KHHfL2Rvkms",
  "dWhBL9X-ihw",
  "fcThyeuJBec",
  "nTVdFeOriIc",
  "ubPzzG1Neq0",
  "mLe0xQQcikQ",
  "Owifjim8xL8",
  "b2AIJ6s0C_A",
  "FaAaC4vLMU0",
  "miR5V6vUsw8",
  "m5CkYNcW4vU",
  "9-kS3jEzVeg",
  "h6ZGkFWt8ts",
  "wWjSssXYOSY",
  "xHkklQh1uS8",
  "hoY0jtQ4h-o",
  "h5JC02ne81w",
  "YdG9Yj9v3DI",
  "VW56wVCtsXM",
  "FKnVhSp0vFM",
  "MzqhQ5Gs1B8",
  "P-obAOr40ek",
  "eqEmKqZ4mT8",
  "PpYcOpLFmHg",
  "02cwj_mK3_Y",
  "NIOsIghuhM8",
  "Svmrq9Xg4_0",
  "4hGDddRQnN0",
  "PJ6Eu2PXA00",
  "g24kNfxsBGE",
  "PfmGej48v0Q",
  "iKTgR0Ur2cU",
  "SZ_gBACzCxQ",
  "sKIU2xXRlpE",
  "-kJkCT5vkkw",
  "2HhJhuaFaBM",
  "hbXtuMNLtKI",
  "uK83L8aGes0",
  "UJxfFNLEmvc",
  "jM04Uwiqfh4",
  "jYlLv0z-sjU",
  "KJb52ybjAtY",
  "5_b35CG0zPQ",
  "zxsit6vn7Co",
  "ty4wBfdNJrM",
  "l7MJ0aA78fc",
  "m_QBH_rCLv0",
  "kiOJpAPHF5E",
  "5p47fKjFq4Y",
  "FySnGU37i2s",
  "IfDe-bd1dlE",
  "HXUH3rwZPQk",
  "gu-xw7ZsE3Q",
  "Eov-hjk-y4U",
  "whnZFzz2NP8",
  "kPmEMhtXrDU",
  "fLSy-uZ1O0s",
  "Rf5yGaALwW4",
  "SH6SX7YKY9c",
  "sb_9yOUKx08",
  "DLOovD_1-Tc",
  "DMZaASBSJYQ",
  "qwQbEgJ8v3E",
  "rfHospH6SV0",
  "DqM7S_TUaNw",
  "95Fon4IaiWg",
  "jMT_sgEYSzs",
  "5orDaiv6UYg",
  "zvmZN3RUQ_g",
  "1siribuntDA",
  "e1uR_kzQyVY",
  "aqC6QH9aMSQ",
  "Kty3zD6b7kM",
  "MgWfrPD71ME",
  "tuS2dosNTpo",
  "P474IUlEO2Q",
  "22Lf_uii0Hg",
  "u-ratvu8vmQ",
  "azli9jA6NyM",
  "V21DUmlsXEs",
  "gIj1oPzuWlk",
  "VItw-ja--ng",
  "hsFzFuatC9A",
  "hE4udPHvYkw",
  "u-XjprSgqVA",
  "pkwwoyH4Go8",
  "V9fEFjBaOZE",
  "f29cje8m0Bg",
  "FQOVUkpiLnc",
  "WHkewGc9n58",
  "c73wFvKkatc",
  "1T3Uq03iGT8",
  "MBW3SwAS1oc",
  "zx9mpels2SI",
  "9kXaZUPwCwg",
  "sbwBb64tzSw",
  "pz5c4JCZMmo",
  "v5UOhBVJzPA",
  "w3498O0ajnU",
  "zTYeXbYUebQ",
  "MTMYqzpTPJI",
  "Si8PyULlt7Q",
  "ob8wOG-pYjM",
  "iWFmyV5XGCw",
  "yEat9fB2ZbU",
  "eACOW7kx8D4",
  "QY1aKAaFKc0",
  "V2ab2Yau19o",
  "CqZk_uYOd7Q",
  "Q2uCgulo_Ws",
  "5_8IwQIB7hk",
  "UoDc977n5jU",
  "DFeXqB6kyBs",
  "fRrAy90I980",
  "Rr0OePU6NN4",
  "Sis8Hn4s8XI",
  "MDMHA8beHpg",
  "3siNPB4fa60",
  "ZOG8Tx-nKX8",
  "Bg0a0bnz0mw",
  "3Zjz0FxxGoQ",
  "XI9Htpx-RyI",
  "QWxaWaLqQ54",
  "uj_SoYZc2RE",
  "kM47Z2d1xcc",
  "eIsSxcQLiuU",
  "s4Ucm8Qp9uU",
  "04r-jQek6jk",
  "8LlfDnKa9mM",
  "gD1lo2oNkrg",
  "wvoVCxJKaOc",
  "gArm7tGr-uw",
  "UBsAqXna0QI",
  "UYHXUylYJy8",
  "4bssDBhLBUk",
  "xJ9PbQHikx4",
  "0XtupTJNQC8",
  "VKgRjFgHPNw",
  "67cOdCYD8qg",
  "-2MkQ2WkWEo",
  "MpJsYFZtQbw",
  "q7mVzgBibaU",
  "zJYcTSQdPBM",
  "G8kHaz5__Ww",
  "8N4v4Fb9k-I",
  "6QP3CIX_Z08",
  "Z6rQuRgbht8",
  "iZ9cfulFLCk",
  "nj-VNOTcAW0",
  "QAZ1XcR-J_A",
  "qflH4-DPxZI",
  "ygS5WaOtm3Y",
  "75p--MXbmJo",
  "rab82k-1XcQ",
  "2It3LYSKyhY",
  "_JzJy3PMXMY",
  "8vM3a7ctFUM",
  "r2IEcrgQZNU",
  "PTrzDcYson4",
  "vRHQqZAlHTs",
  "UNepTmjevxg",
  "CvUl5sf1fjw",
  "RFM5vxxLGbc",
  "AGbOQ8PoXP4",
  "xbsI-xVesVs",
  "y3w5RhUoSYg",
  "5o8_x93BYFw",
  "jByJ6BywK3s",
  "9LW1l4sIFgI",
  "csnZPX5H-y8",
  "X4qqpP4mFFw",
  "LXU5RQOS4jA",
  "EIwGA_ng_Gs",
  "RHR4IQO4e2Y",
  "xd8cHjcUz0U",
  "NcngBJkv-68",
  "aLN9A5HdhQE",
  "6BjuUtk2mFo",
  "5cPNEy41qaI",
  "1dwMqg28HIw",
  "wfWEkzxgvZI",
  "MSD1y8M7ep0",
  "xHx-pPGUH9E",
  "rhw4Y4z2N20",
  "NSCb8ulC_4A",
  "ZpuMfyPiPjc",
  "GKYvNJRf6KI",
  "oWVO5oIfifE",
  "y63ilYpBqG8",
  "D3CBLQ8QJjg",
  "YnDOKUZY2sU",
  "pI1j-OJLhzE",
  "x0PW3D944dk",
  "5l3S9W0gRuY",
  "fzfCQX9NWNI",
  "6rB06sa8Ww0",
  "l8cL7j9oL1o",
  "_ZCyoE4bO0M",
  "kPW2JqK31WQ",
  "Du2wAPQF-WA",
  "K7eOfq-Mtwc",
  "dHW3_DTpNxM",
  "HUF3wCR7KfY",
  "_1blL5z8MQs",
  "x0tvBLzac-Q",
  "qfyfXe8lJN4",
  "N9NSZHSpOdA",
  "Ky-dnbjFruI",
  "5ILRagsmcuA",
  "d1UFZWMvfcU",
  "Q_F44-kKDNc",
  "KR7F-3DCySI",
  "MZrDyzUzLeA",
  "zkpGnscyk8E",
  "V7zV3xCF5vw",
  "nxM-q1_tHYc",
  "rSQAuh7PXVM",
  "QXewxTCMT_o",
  "uhlUbikVqB8",
  "FxSBjSRMwgE",
  "dc2MXuPu0Yw",
  "wKzy-xoO4xA",
  "QPxc5z7czrY",
  "fYV8Eh-oHgk",
  "9FsSyZmQN4I",
  "l_7mVg-cefU",
  "LbKHhM6ltC8",
  "RQZe1l4sWQA",
  "AcL4PMsXDq8",
  "ZRHjvktDuqU",
  "IYYCsj27eZk",
  "XynAE9fn90g",
  "9o78EWigPOc",
  "WRL29NPnmMY",
  "nRk7PW9uqGg",
  "a8nNJoDub2g",
  "cIHrHJuY2pE",
  "d81HX2j4rII",
  "JBe78nikdRA",
  "6KeiL5F3nlk",
  "YYJ68yi_3aY",
  "0RSwTBpSqOY",
  "3xceDpk52xA",
  "h-8aBouP4Qo",
  "2ho3QpCX6sM",
  "yBomug2AQTE",
  "4WluQg6gR-o",
  "vZEn3UOPBq4",
  "uyaX4lRBkwc",
  "SHTTozeaqBY",
  "0wH_-7E_iI0",
  "czuNLQYzGWw",
  "5_p4os_viDg",
  "FqnEmaXkZFc",
  "iQViFCCW5-8",
  "83DraLjCNPg",
  "7Lgk-6Q5Eic",
  "4sstApnRIHU",
  "gGHBF925IMA",
  "5q4khw71mbY",
  "-lUs6pNtRqI",
  "iFS6yv9YPbc",
  "rCDA3cB-O1U",
  "KNjKO2BqP34",
  "totm-VtkdP0",
  "E_RFS0AdZLg",
  "dpBzoIe7yi8",
  "hd18DRUd9YQ",
  "td-3GOF967Q",
  "eGGZ23RPWIs",
  "bmE91LUt3-4",
  "-ywGPbYWOr4",
  "ViyY0wtYtTw",
  "knwsL8-7IsE",
  "mV69SV2ghH0",
  "4Xcjdq5LkmQ",
  "zoBdT4D8Lpg",
  "TqCpuIXC_Wk",
  "Dv3mbPZ4tQI",
  "20NLIVccPf0",
  "mQtbO1AOeCs",
  "bW-UK20Nehg",
  "qHhTrSWnPuo",
  "J58obxxB_vk",
  "YZhve0-Ep2Q",
  "8Tb-CHjgsr8",
  "nIW4kLyVbAA",
  "UGjEY1KtDUo",
  "4Tue-aJ7rh4",
  "_jjUoDXWPDI",
  "yOgaLbJ4CII",
  "IRZtqv0nMCc",
  "wJTKhL1hWUQ",
  "q9djN3MQbzw",
  "b6lNUUdi-vU",
  "enAQOOxUasw",
  "MNpGtkA-lWo",
  "bw7w-K5JJm8",
  "WGCzLfXqIIM",
  "tZBuK9SSnxA",
  "vqzMdWcwSQs",
  "n4NliYU8Oec",
  "mUVwW4eYj3Y",
  "SJ158CpsrXo",
  "SaVECszwwGI",
  "fvUbT6uvH2s",
  "s3QJ3gSWZNk",
  "WAY3vXhirjg",
  "6gNCEodKz2k",
  "Cllq6WvM2TA",
  "mxAiCcb0Xac",
  "mxDW0_uDLE4",
  "fN_MgWzGsCI",
  "V9unZ6Jd318",
  "-GSvmwL_eEU",
  "U5rvZq_QZEY",
  "oADVqvrhygE",
  "CLvAksknvCQ",
  "y-rR25W2388",
  "RfB7rj6ekfs",
  "0215zfIujjM",
  "tKdh5EVQjEE",
  "g0ipCtEH_z0",
  "zNdpFE3QmeA",
  "J4M2LVUEqVw",
  "oF0RvRfAkCQ",
  "dmNrIzQuuxQ",
  "Ecw91cSZu5k",
  "H8VBbgqxbls",
  "kuCxKrcmjfM",
  "QvkdeOofb3U",
  "fNve6tvD1BI",
  "m63J7AlO8Eo",
  "HrjtRqWAyRk",
  "nRLaUDv0QRQ",
  "5ex0GUzfq-k",
  "52sIqc6G5DY",
  "hjNnNKuspbQ",
  "SpqYbPm6pjQ",
  "uY8hz6USA6E",
  "YJgvrQXlRaw",
  "uEA05VPUMOU",
  "0tVXr-rW1pg",
  "KS544mlLz5s",
  "KMTJ43DHz7k",
  "-t37Z1FkcGg",
  "WYmRiPTpVAo",
  "ZFnizww3JJg",
  "-6zpDZ99l4w",
  "54MU6ren1Bw",
  "QhFJNrHvtLg",
  "tZeqaoGMeXw",
  "PwDGl5cOXho",
  "t80VYAlDzxY",
  "FpJjkvD3yxc",
  "MzJor0BgMoA",
  "AkOJV2euVis",
  "hOmiXtkYFC8",
  "O9D-7pCBxMw",
  "8aZPeck1dbE",
  "-1Yu-_zjwoA",
  "G23Ytsruu78",
  "y-DauEqdwjY",
  "CW8pAwrdPQY",
  "WR7Jml3npr8",
  "AoU9lzhiEq4",
  "8PepNE7y7NQ",
  "azu_INLYOXw",
  "a1yX2mE1dpc",
  "gZkulknOJrM",
  "p4lN7el3v2E",
  "GBp3dhvZV10",
  "lBUAlsCaauc",
  "_VRwXLRTXyU",
  "vknikxe8JHo",
  "IMX9CGkqpI4",
  "BlNr_4KdNIE",
  "Z5Fm70MFgZE",
  "uK4WVxyjw3g",
  "HCWadzTxPBo",
  "tvWVquLR5I4",
  "MwRu4iXzdFg",
  "3IEyDngvlGY",
  "KcAWtUcxp1s",
  "B0N_4A-Eh-w",
  "4LTeWd50rYc",
  "A4pCsW4mZlY",
  "aMv2aCTcAC0",
  "5lXEW1FfI8U",
  "A0Vbp2fg2bE",
  "nDyGj3zpvOk",
  "jABkY8hFG38",
  "YPz59nZeYbU",
  "y0t697DiOvk",
  "5k3ClC5n4tg",
  "ZQhZKEliJmQ",
  "xlaBe2tlW_Q",
  "4U6rIZf_G1c",
  "-vpdCwIUeOw",
  "-AXT7rre8xQ",
  "vf6VPrYWFHQ",
  "TmtWITllnvw",
  "ft6SCqc_tow",
  "_SIJwzQWorw",
  "2yyGlDzCMGU",
  "meZ-RqAs8Wc",
  "v9Ldb871uBA",
  "NHV7BBN4B7c",
  "DCki5yk7D-Q",
  "M5CeQG1YfEQ",
  "Mkeeqvv4w50",
  "5lfaq_Yo7Tg",
  "B9QcEBgrvQY",
  "1wRSPZRzcZQ",
  "WPQyX_wq6Ik",
  "qO7-UjxhU1Q",
  "MphznLB38C8",
  "P8aNtZ7xptU",
  "SvAGAU0WsMM",
  "fQb7-hppn30",
  "3Lv8IhtxtZQ",
  "_aY-ET_KoxU",
  "y81K6_0w7Ak",
  "Dyc8-jpsvfQ",
  "Lz3hPMmlZhE",
  "NP_C4ysUkEA",
  "l3tmaKcKivU",
  "H_cHqoP3-oY",
  "W3u6Mau2gQw",
  "X7brAAzwANA",
  "PuyZ_VN6tbk",
  "OXQg57Ow-Fg",
  "NsbxatElE58",
  "Kgcm-qzmUOo",
  "g703OR7Shcw",
  "tHfTktHTSK4",
  "LO90MhLeP0Q",
  "XKKdefAF2dc",
  "2_bR0DVLTqA",
  "Rs0uvkClHvg",
  "GoSmH26AON8",
  "bGPOGtxmoJQ",
  "7B_ToG_0U3k",
  "EaUconYBiDU",
  "M4CJnmwfolw",
  "1MIJCZeRzVw",
  "cCo-SrTlxw8",
  "k6yJVQVI0ME",
  "7el7jm1iJE8",
  "DkQaBtzFoqo",
  "FhijL5NN7Ws",
  "3OyC1D11BJ8",
  "VFMnB-Z4H44",
  "K9HV8GgjCUs",
  "hCmKVQ-vpsI",
  "_hNRpI4-B8I",
  "yZVTRoUFaek",
  "OpNDMT8j884",
  "SbLWooziQLg",
  "TCO7RLHldzE",
  "_LI5jIbE74I",
  "l86yDLw40aU",
  "lxfraBp3lxs",
  "KvoU21m0PSI",
  "aASDLfZTRZg",
  "kn2Vwy6Injo",
  "MWNZ82OWIus",
  "tn7hqdjyq-I",
  "zLEfwQ2lRvk",
  "ksQIsCxiWvw",
  "_wGJAEzTWzY",
  "lGJTsuNCTco",
  "ByDg0yFPxfE",
  "-73oVm7g6Ps",
  "Y3HVoiuzA4M",
  "ooNVGnSwLec",
  "mUUv3xcmvkA",
  "ck0gD8bxNNU",
  "v3KDE-spiz4",
  "YheeZ0DXxHY",
  "-htIwUregfc",
  "UAFfCHBLEQQ",
  "sutvJjJPPDk",
  "JuhyCoygdJo",
  "lAw00GvDKIs",
  "V8l-L9rNdV0",
  "4egIAOPp3Wc",
  "wPOch2G4MMc",
  "CwD_TvePkDw",
  "s2FyNNImGAk",
  "o7a8jZZoSoA",
  "_4PRe-pbYTw",
  "IM3hHviFMk4",
  "kxUQOcaT53g",
  "YY2gX68ikd4",
  "HIYigGOfxfQ",
  "GJaFQG5Jx8Y",
  "Sji2xevrfCw",
  "SvSDS3eh4ns",
  "zU7GWcZfycI",
  "aPjVTy0_ZFA",
  "55snVqj9FuE",
  "RZ7GTg6iknE",
  "jbP4Ska6anw",
  "jlxzZ1ZeshE",
  "EziNkimvsqI",
  "R32Ntt8MOmE",
  "HM2JpZg-Qx0",
  "cehQA7k13Dk",
  "eO4em6oefbM",
  "RDf6oce2a1Y",
  "lHnblr11Np8",
  "b3iKJ10UAnE",
  "EyYj80XEcRk",
  "8JzED4-CVmM",
  "z4FfxwTULqc",
  "BbbcvFJ55F4",
  "DMXORaV1jSQ",
  "rRwzN523UvE",
  "pTDvW2fYNB0",
  "oHksuJShN7k",
  "Be1UyZorMeQ",
  "mr6sTqvpb5I",
  "V5MsM4mUjuk",
  "48sdUzGfjHU",
  "EUunC0kpbYE",
  "KmWtsP2lPOc",
  "M3Ov5ZSk6Nc",
  "hBdixW9QHb4",
  "Pk44fCQzplQ",
  "e_oQY83DbG8",
  "cMFX3jmIB00",
  "hT4YSQSED9Q",
  "ClRU3lWUQTk",
  "pCas579rdZI",
  "dzc1SuIqOzw",
  "F2WdcYG1dFU",
  "vTXbbROfbeo",
  "sixsIHYI25A",
  "PwPRd_NauEk",
  "_7Q2KfDLwQg",
  "BwnPhG4YzAs",
  "_FBlCc7n1-E",
  "ICU9FVo6f5Q",
  "k3VBI1RHRaE",
  "dHd8gdBi3nU",
  "cRLHDRUKzi8",
  "r36YlUpEJLc",
  "kHKM16BN2VQ",
  "RbEtGLfgJjc",
  "78LdPbZyvAI",
  "lYHBV_36gu8",
  "iCLytA1MPAQ",
  "aAoDFB4Y80E",
  "NaoIA7E3q7w",
  "fnH7n7VgqHY",
  "Gtvx0x-bFoA",
  "Oj0iFoKy8fQ",
  "HeQjXwfx2Fk",
  "8xH91WfXVqo",
];

export function VideoProvider({ children }) {
    const [useIndex, setIndex] = useState(0);
    const [useVideoArray, setVideoArray] = useState(placeholdervideos);
    let useVideoId = useVideoArray[useIndex];
  return (
    <VideoContext.Provider value={{ useIndex, setIndex, useVideoArray, setVideoArray, useVideoId }}>
      {children}
    </VideoContext.Provider>
  );
}

export function useVideo() {
  return useContext(VideoContext);
}